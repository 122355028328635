
import qs from "qs";
import {
  communicationMapActions,
  communicationMapState
} from "@/store/modules/communication";
import { format } from "date-fns";
import * as selectOptions from "@/helpers/selectOptions";
import Vue from "vue";
export default Vue.extend({
  name: "CommunicationReaders",
  props: {
    communicationId: {
      type: String,
      required: true,
      default: ""
    }
  },
  data() {
    return {
      maxNumberOfPagers: 7,
      offset: 0,
      currentPage: 1,
      limit: 10
    };
  },
  methods: {
    ...communicationMapActions(["getCommunicationAcknowledgedNotifications"]),
    async fetchCommunicationReaders(
      query: Record<string, any> = {}
    ): Promise<void> {
      if (!this.communicationId || this.communicationId.length < 12) return;
      try {
        await this.getCommunicationAcknowledgedNotifications({
          communicationId: this.communicationId,
          query
        });
      } catch (error) {
        //do nothing
        this.$bugSnagClient.notify(error);
      }
    },
    paginationPageChanged(pageNumber: number): void {
      this.currentPage = pageNumber;
      const page: any =
        this.readersData.links && this.readersData.links.pages
          ? this.readersData.links.pages.find(
              (page: any) => page.page === pageNumber
            )
          : null;
      if (page) {
        let queryString = page.url.split("?")[1];
        const queryObject = qs.parse(queryString);
        this.limit = queryObject.__limit;
        this.offset = queryObject.__offset;
        this.fetchCommunicationReaders(queryObject);
      }
    },
    getUserRoleLabel(role: string) {
      const roleOption = selectOptions.userRoles.find(
        (option: any) => option.value === role
      );
      if (roleOption) return roleOption.label;
      return "N/A";
    }
  },
  computed: {
    ...communicationMapState(["makingApiRequest", "readersData"]),
    overallReadersCount(): number {
      return (
        this.readersData.links.totalRecords || this.readersData.readers.length
      );
    }
  },
  watch: {
    communicationId: {
      immediate: true,
      handler() {
        this.fetchCommunicationReaders({
          __limit: this.limit,
          __offset: this.offset
        });
      }
    }
  },
  filters: {
    formatDate: (val: string) => format(new Date(val), "yyyy-MM-d")
  }
});
